import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    gutter: 20,
    class: "align-items-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        class: "py-1",
        xs: 24,
        sm: 8,
        md: 6,
        lg: 4,
        xl: 3
      }, {
        default: _withCtx(() => [
          _createElementVNode("label", {
            class: _normalizeClass([`d-flex align-items-center`, _ctx.labelClasses, _ctx.isBolder ? 'fw-bolder' : ''])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass({ required: !_ctx.readOnly })
            }, _toDisplayString(_ctx.label), 3)
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        class: "py-1",
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 3
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_autocomplete, {
            id: `${_ctx.id}_tai-khoan`,
            class: "w-100",
            clearable: "",
            disabled: _ctx.readOnly,
            "value-key": "ten_tai_khoan",
            modelValue: _ctx.maTaiKhoanDangChon,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.maTaiKhoanDangChon) = $event)),
            "trigger-on-focus": true,
            "fetch-suggestions": _ctx.queryTaiKhoan,
            onSelect: _ctx.onSelectTaiKhoan,
            onFocus: _cache[1] || (_cache[1] = ($event: any) => ($event.target.select())),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.iconClickedHandler('tai_khoan')))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", null, [
                _createElementVNode("strong", null, _toDisplayString(item.ma_tai_khoan), 1)
              ]),
              _createElementVNode("span", null, " - " + _toDisplayString(item.ten_tai_khoan), 1)
            ]),
            _: 1
          }, 8, ["id", "disabled", "modelValue", "fetch-suggestions", "onSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        class: "py-1",
        xs: 12,
        sm: 10,
        md: 4,
        lg: 5,
        xl: 5
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_autocomplete, {
            id: `${_ctx.id}_chi-tiet`,
            class: "w-100",
            clearable: "",
            ref: "maChiTietInputRef",
            modelValue: _ctx.maChiTietDangChon,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.maChiTietDangChon) = $event)),
            "value-key": "ten_tai_khoan",
            "trigger-on-focus": false,
            "fetch-suggestions": _ctx.queryThongTinChiTiet,
            disabled: _ctx.isInputTaiKhoanChiTietDisabled || _ctx.readOnly,
            onSelect: _ctx.onSelectTaiKhoanChiTiet,
            onFocus: _cache[4] || (_cache[4] = ($event: any) => ($event.target.select())),
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.iconClickedHandler('chi_tiet')))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", null, [
                _createElementVNode("strong", null, _toDisplayString(item.ma_tai_khoan) + " / " + _toDisplayString(item.ma_chi_tiet), 1)
              ]),
              _createElementVNode("span", null, " - " + _toDisplayString(item.ten_tai_khoan), 1)
            ]),
            _: 1
          }, 8, ["id", "modelValue", "fetch-suggestions", "disabled", "onSelect"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        class: "py-1 hidden-md-and-up",
        xs: 24,
        sm: 8,
        md: 6
      }),
      _createVNode(_component_el_col, {
        class: "py-1",
        xs: 24,
        sm: 16,
        md: 12,
        lg: 12,
        xl: 12
      }, {
        default: _withCtx(() => [
          (_ctx.tenTaiKhoanChiTiet)
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: _ctx.tenTaiKhoanChiTiet,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tenTaiKhoanChiTiet) = $event)),
                class: "w-100",
                type: "text",
                disabled: ""
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                modelValue: _ctx.tenTaiKhoan,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tenTaiKhoan) = $event)),
                class: "w-100",
                type: "text",
                disabled: ""
              }, null, 8, ["modelValue"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}